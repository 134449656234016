import React from 'react';
// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <body id="page-top">
            <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
            <div class="container">
                <a class="navbar-brand js-scroll-trigger" href="#page-top"><img src="/static/img/navbar-logo1.png" /></a><button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="true" aria-label="Toggle navigation">Menu<i class="fa fa-bars ml-1"></i></button>
                <div class="collapse navbar-collapse " id="navbarResponsive">
                    <ul class="navbar-nav text-uppercase ml-auto">
                        <li class="nav-item"><a class="nav-item js-scroll-trigger" href="#services">Services</a></li>
                        <li class="nav-item"><a class="nav-item js-scroll-trigger" href="#portfolio">Specialization</a></li>
                        <li class="nav-item"><a class="nav-item js-scroll-trigger" href="#about">About</a></li>
                        <li class="nav-item"><a class="nav-item js-scroll-trigger" href="#team">Team</a></li>
                        <li class="nav-item"><a class="nav-item js-scroll-trigger" href="#contact">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
        </body>
        <header class="masthead">
            <div class="container">
                <div class="masthead-heading text-uppercase">Rushikha IT Solutions!</div>
                <div class="masthead-subheading">"Embrace The Unknown"</div>
                <a class="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="#services">Tell Me More</a>
            </div>
        </header>
        
        <section class="page-section" id="services">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Services</h2>
                    <h3 class="section-subheading text-muted">our services include</h3>
                </div>
                <div class="row text-center">
                    <div class="col-md-4">
                        <span class="fa-stack fa-4x"><i class="fa fa-circle fa-stack-2x text-primary"></i><i class="fa fa-shopping-cart fa-stack-1x fa-inverse"></i></span>
                        <h4 class="my-3">Professional Services</h4>
                        <p class="text-muted">Change in business is rapidly becoming fast paced, and technology needs to be responsive and flexible to sustain
this momentum. Keeping up with this transformation across organizational processes and functions, everyone needs
to view IT Infrastructure as a strategic asset and leverage it to drive the business momentum.
At Company Name we have qualified and seasoned management team. Our experienced IT professionals are
carefully chosen to fit our diverse clients with diversified technical needs, expectations and work culture. We
continuously strive to stay in the forefront of technology.</p>
                    </div>
                    <div class="col-md-4">
                        <span class="fa-stack fa-4x"><i class="fa fa-circle fa-stack-2x text-primary"></i><i class="fa fa-laptop fa-stack-1x fa-inverse"></i></span>
                        <h4 class="my-3">Application Services</h4>
                        <p class="text-muted">Our Company has a team of experts in various verticals of the IT Industry and with years of business experience
that are focused in designing, defining, developing and delivering innovative business solutions that address the
core needs of the respective industries. This unique combination of all-in-one knowledgeable professionals with
strong industry experience and with business process and domain expertise, knowledge and expertise of regulatory
norms worldwide can support our customers in swiftly deploying business solutions and most importantly giving
them the peace of mind with a better return on investment.</p>
                    </div>
                    <div class="col-md-4">
                        <span class="fa-stack fa-4x"><i class="fa fa-circle fa-stack-2x text-primary"></i><i class="fa fa-lock fa-stack-1x fa-inverse"></i></span>
                        <h4 class="my-3">HR Related Services</h4>
                        <p class="text-muted">Employee Login </p>
                          <br/>
                        <p>Employee pay check login</p>
                    </div>
                </div>
            </div>
        </section>
      
        <section class="page-section bg-light" id="portfolio">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Specialization</h2>
                    <h3 class="section-subheading text-muted">We train you on</h3>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-sm-6 mb-4">
                        <div class="portfolio-item">
                            <a class="portfolio-link" data-toggle="modal" href="#portfolioModal1"
                                ><div class="portfolio-hover">
                                    <div class="portfolio-hover-content"><i class="fa fa-plus fa-3x"></i></div>
                                </div>
                                <img class="img-fluid" src="assets/img/portfolio/01-thumbnail.jpg" alt=""
                            /></a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading">Python</div>
                                <div class="portfolio-caption-subheading text-muted">Django and Flask frameworks</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb-4">
                        <div class="portfolio-item">
                            <a class="portfolio-link" data-toggle="modal" href="#portfolioModal2"
                                ><div class="portfolio-hover">
                                    <div class="portfolio-hover-content"><i class="fa fa-plus fa-3x"></i></div>
                                </div>
                                <img class="img-fluid" src="assets/img/portfolio/02-thumbnail.jpg" alt=""
                            /></a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading">SAP</div>
                                <div class="portfolio-caption-subheading text-muted">It's Core services</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb-4">
                        <div class="portfolio-item">
                            <a class="portfolio-link" data-toggle="modal" href="#portfolioModal3"
                                ><div class="portfolio-hover">
                                    <div class="portfolio-hover-content"><i class="fa fa-plus fa-3x"></i></div>
                                </div>
                                <img class="img-fluid" src="assets/img/portfolio/03-thumbnail.jpg" alt=""
                            /></a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading">Microsoft Azure</div>
                                <div class="portfolio-caption-subheading text-muted">Understanding the principles</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb-4 mb-lg-0">
                        <div class="portfolio-item">
                            <a class="portfolio-link" data-toggle="modal" href="#portfolioModal4"
                                ><div class="portfolio-hover">
                                    <div class="portfolio-hover-content"><i class="fa fa-plus fa-3x"></i></div>
                                </div>
                                <img class="img-fluid" src="assets/img/portfolio/04-thumbnail.jpg" alt=""
                            /></a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading">Bussiness Analysis</div>
                                <div class="portfolio-caption-subheading text-muted">Data Warehousing</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb-4 mb-sm-0">
                        <div class="portfolio-item">
                            <a class="portfolio-link" data-toggle="modal" href="#portfolioModal5"
                                ><div class="portfolio-hover">
                                    <div class="portfolio-hover-content"><i class="fa fa-plus fa-3x"></i></div>
                                </div>
                                <img class="img-fluid" src="assets/img/portfolio/05-thumbnail.jpg" alt=""
                            /></a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading">Project Management</div>
                                <div class="portfolio-caption-subheading text-muted">Drive the project's</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="portfolio-item">
                            <a class="portfolio-link" data-toggle="modal" href="#portfolioModal6"
                                ><div class="portfolio-hover">
                                    <div class="portfolio-hover-content"><i class="fa fa-plus fa-3x"></i></div>
                                </div>
                                <img class="img-fluid" src="assets/img/portfolio/06-thumbnail.jpg" alt=""
                            /></a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading">Scrum Master</div>
                                <div class="portfolio-caption-subheading text-muted">Get certified with ease</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="page-section" id="about">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">About</h2>
                    <h3 class="section-subheading text-muted">Our Humble Beginnings</h3>
                </div>
                <ul class="timeline">
                    <li>
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="static/img/about/1.jpg" alt="" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>2020</h4>
                                <h4 class="subheading">How we started</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">“Rushikha IT Solution's” is a growing IT consulting firm governed by its ethics and core values set by the President.
Rushikha IT Solutions is a leading provider of IT Solutions and services to businesses across United States and has earned
the reputation of being an excellent employer. Attracted by its reputation as an excellent Employer, IT Professionals
opt to work with Company Name more than any other company. Company Name growth and success can be
attributed in large part to the dedication and skill of our Associates and unparalleled support from our Clients.</p></div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/2.jpg" alt="" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>February 2020</h4>
                                <h4 class="subheading">An Agency is Born</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Rushikha IT Solutions provides a wide range of professional services that help clients/Vendors to meet challenges and
respond to opportunities wherever raised. We don’t wait for opportunities at Rushikha IT Solutions, we create
opportunities. We don’t believe in the saying “opportunity knock once” but we create opportunity and let the door
open by itself.
At Rushikha IT Soulutions Progressive, innovation and speedy transformation has been our main motto, Our Team is
drawn from different backgrounds and is united by collective ideas and values from different experienced
individuals which help to fulfill the needs of vendor/client. We work in environment where we learn, develop, utilize
(different skills) and everyone is given the opportunity to be the best they can be. This loom gives “Rushikha It Solutions”
an invaluable depth and experience helping provide clients/vendors with an array of ideas, perspectives,
capabilities and experience to choose Rushikha IT Soulutions.</p></div>
                        </div>
                    </li>
                    <li>
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>April 2020</h4>
                                <h4 class="subheading">Transition to Full Service</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">In today’s world of challenging situations and constant developments in the field of Information technology, we
respond with core values to fulfill each IT needs and believe in the strength & experience of each individual working
with Rushikha IT Solutions
“Coming together is a beginning. Keeping together is progress. Working together is success.”
We work as a TEAM: Teamwork is the ability to work together toward common vision. The ability to direct
individual accomplishments toward organizational objectives. It is the fuel that allows common people to attain
uncommon results.”
SUCCESS IS OUR STRENGTH: “If everyone is moving forward together, then success takes care of itself.”
Work for Excellence: “When a team outgrows individual performance and learns team confidence, excellence
becomes a reality.”</p></div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>May 2020</h4>
                                <h4 class="subheading">Why us</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Our entire business plan is all about you the Employer, Vendor and Client
Our Company is strong follower of some of the ETHICS:
Be Trustful: We recognize customers want to do business with a company they can trust; when trust is at the core
of a company, it's easy to recognize. Trust defined, is assured reliance on the character, ability, strength, and truth
of a business
“The glue that holds all relationships together -- including the relationship between the
leader and the lead is trust, and trust is based on integrity.” – That’s what we believe in.

Be Respectful: We treat all our employees, business partners and clients with the utmost respect and courtesy
”If you have some respect for people as they are, you can be more effective in helping them to
become better than they are.”
Meet Obligations: Regardless of the circumstances, we do everything in our power to gain the trust of our entire
customers and clients we try and honor all our commitments and obligations.</p></div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image">
                            <h4>Be Part<br />Of Our<br />Story!</h4>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        
        <section class="page-section bg-light" id="team">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Our Amazing Team</h2>
                    <h3 class="section-subheading text-muted">Strive for better today</h3>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src="assets/img/team/3.jpg" alt="" />
                            <h4>Sukrutha</h4>
                            <p class="text-muted">HR Lead</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-twitter"></i></a><a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-facebook-f"></i></a><a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src="assets/img/team/3.jpg" alt="" />
                            <h4>Karunaker Kaukuntla</h4>
                            <p class="text-muted">Owner& Supervisor</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-twitter"></i></a><a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-facebook-f"></i></a><a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src="assets/img/team/3.jpg" alt="" />
                            <h4>Himavanth</h4>
                            <p class="text-muted">HR Manager</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-twitter"></i></a><a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-facebook-f"></i></a><a class="btn btn-dark btn-social mx-2" href="#!"><i class="fa fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8 mx-auto text-center"><p class="large text-muted">Rushikha IT Solutions is an E-Verify Certified IT Company
E-Verify is an Internet-based system that compares information from an employee's Form I-9, Employment Eligibility Verification, to data from U.S. Department of Homeland Security and Social Security Administration records to confirm employment eligibility.-USCIS Info
Don’t look back as WE ARE E-Verify Certified Company
“ADDRESS”
If you have any questions regarding E-Verify Program please send us an email at hr@companyname.com</p></div>
                </div>
            </div>
        </section>
        
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="#!"><img class="img-fluid d-block mx-auto" src="assets/img/logos/envato.jpg" alt="" /></a>
                    </div>
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="#!"><img class="img-fluid d-block mx-auto" src="assets/img/logos/designmodo.jpg" alt="" /></a>
                    </div>
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="#!"><img class="img-fluid d-block mx-auto" src="assets/img/logos/themeforest.jpg" alt="" /></a>
                    </div>
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="#!"><img class="img-fluid d-block mx-auto" src="assets/img/logos/creative-market.jpg" alt="" /></a>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="page-section" id="contact">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Contact Us</h2>
                    <h3 class="section-subheading text-muted">Dont hesitate to reach out if any questions</h3>
                </div>
                <form id="contactForm" name="sentMessage" novalidate="novalidate">
                    <div class="row align-items-stretch mb-5">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control" id="name" type="text" placeholder="Your Name *" required="required" data-validation-required-message="Please enter your name." />
                                <p class="help-block text-danger"></p>
                            </div>
                            <div class="form-group">
                                <input class="form-control" id="email" type="email" placeholder="Your Email *" required="required" data-validation-required-message="Please enter your email address." />
                                <p class="help-block text-danger"></p>
                            </div>
                            <div class="form-group mb-md-0">
                                <input class="form-control" id="phone" type="tel" placeholder="Your Phone *" required="required" data-validation-required-message="Please enter your phone number." />
                                <p class="help-block text-danger"></p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group form-group-textarea mb-md-0">
                                <textarea class="form-control" id="message" placeholder="Your Message *" required="required" data-validation-required-message="Please enter a message."></textarea>
                                <p class="help-block text-danger"></p>
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <div id="success"></div>
                        <button class="btn btn-primary btn-xl text-uppercase" id="sendMessageButton" type="submit">Send Message</button>
                    </div>
                </form>
            </div>
        </section>
        
    </div>
  );
}

export default App;
